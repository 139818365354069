<template>
	<main>
		<div class="app-heading pd:md">
			<div class="app-title" v-t="'favourites'"></div>
		</div>

		<section>
			<div v-if="isLoading" class="preloader"></div>

			<div v-else>
				<div class="pd:xl">
					<div v-if="favData.length > 0">
						sth
					</div>

					<div class="placeholder" v-else>

						<div class="placeholder-thumbnail">
							<img
								src="@/assets/images/icon-favourite-outline.png"
								alt="icon of favourite in love shape">
						</div>

						<div class="placeholder-description">
							<div v-t="'favourites_empty_placeholder_p1'"></div>
							<div v-t="'favourites_empty_placeholder_p2'"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: 'favourites',
	data () {
		return {
			isLoading: false,
			favData: []
		}
	}
}
</script>

<style lang="scss" scoped>
section {
	background-color: $color-background;
	color: $color-foreground;

	.placeholder {
		text-align: center;

		> *:not(:last-of-type) {
			margin-bottom: $pd;
		}

		.placeholder-thumbnail {
			img {
				width: 3em;
			}
		}

		.placeholder-description {
			@extend %text-sub;

			margin-left: auto;
			margin-right: auto;
			max-width: 15em;
			color: inherit;
		}
	}
}
</style>
